<template>
  <v-bottom-sheet
    v-bind="$attrs"
    v-on="$listeners"
    :persistent="persistent"
    scrollable
  >
    <v-card class="card-content">
      <div class="d-flex justify-space-between py-3 px-5">
        <v-btn icon small fab @click="$emit('cancel')" :disabled="notificationSheet">
          <v-icon v-if="!notificationSheet">mdi-close</v-icon>
        </v-btn>
        <div v-if="isHtml" class="text-center templateLabel f-s-20 f-w-900" v-html="title"></div>
        <div
          v-else
          class="text-center templateLabel f-s-20 f-w-900"
        > {{ title }} </div>
        <v-btn icon small fab @click="onClickConfirm">
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </div>
      <slot></slot>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'wap-bottom-sheet',
  props: {
    persistent: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    notificationSheet: {
      type: Boolean,
      default: false
    },
    isHtml: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClickConfirm: _.throttle(
      function () {
        this.$emit('confirm')
      }, 2000, { trailing: false }
    )
  }
}
</script>
<style lang="sass" scoped>
  .card-content
    border-radius: 12px 12px 0 0
    padding-bottom: 8px
    /*min-height: 260px*/
  .templateLabel
    line-height: 40px
</style>
