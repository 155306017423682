<template>
  <div
    v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.PRE_ORIGINATION_ENTER_LEAVE_CHOOSE_LOAN_AMOUNT.value, path: $route.path}">
    <div class="head-content round">
      <div v-min-height="300">
        <div class="arc-text-container">
          <div id="arc-text-pre-qualified"></div>
          <v-spacer></v-spacer>
          <div id="arc-text-step">$25.00 INCREMENTS</div>
        </div>
        <round-slider
          v-model="roundSliderDefinition.sliderValue"
          :min="roundSliderDefinition.min"
          :max="roundSliderDefinition.max"
          :disabled="layouts.actives.selectAmount"
          startAngle="90"
          endAngle="+360"
          radius="130"
          width="30"
          lineCap="round"
          step="25"
          editableTooltip="false"
          range-color="var(--theme_secondary)"
          path-color="rgba(255,255,255,0.20)"
          :tooltipFormat="currencyFormat"
        />
      </div>

      <div class="px-4 pb-4" v-if="layouts.data.couponTip">
        <div class="coupon-tip pa-1">
          <v-icon color="var(--theme_primary)">mdi-party-popper</v-icon>
          <div class="pl-2 text-white">A coupon has been applied for your loan.</div>
        </div>
      </div>

    </div>

    <div class="main-content">
      <v-alert
        dense
        text
        border="left"
        color="var(--theme_primary)"
        class="f-s-12"
      >
        Please choose an amount with a minimum of
        <span class="f-w-700">{{ assist.currency.format(roundSliderDefinition.min) }}</span> and up to
        <span class="f-w-700">{{ assist.currency.format(roundSliderDefinition.max) }}</span>.
      </v-alert>
    </div>
    <div class="footer-wrap">
      <div class="link pb-3" @click="layouts.dialog.changeBankAccount = true">I want to change bank account</div>
      <wap-btn
        width="100%"
        :disabledControl="layouts.actives.selectAmount"
        @click="onClickContinue"
      >Continue
      </wap-btn>
    </div>

    <wap-bottom-sheet
      v-model="layouts.dialog.changeBankAccount"
      :persistent="true"
      title="Optional"
      @cancel="layouts.dialog.changeBankAccount = false"
      @confirm="onClickChangeBank"
    >
      <v-card-text
        class="f-w-300 f-s-16 pb-5"
      >
        Your pre-qualified amount may change for the new bank account, please click <span class="color-primary">✓</span> to continue or click <span class="color-secondary">X</span> to cancel.
      </v-card-text>
    </wap-bottom-sheet>
  </div>
</template>

<script>
import { OnlineApi } from '@/api'
import RoundSlider from 'vue-round-slider'
import ArcText from 'arc-text'
import { Tool, DataType, Currency } from '@/assets/js/util'
import WapBtn from '@/components/base/WapBtn'
import WapBottomSheet from '@/components/base/WapBottomSheet'

const tool = new Tool()
const currency = new Currency()
export default {
  name: 'ChooseLoanAmount',
  components: {
    RoundSlider,
    WapBtn,
    WapBottomSheet
  },
  data () {
    return {
      assist: {
        currency: currency,
        dataType: DataType
      },
      roundSliderDefinition: {
        min: 200,
        max: 201,
        sliderValue: 200
      },
      layouts: {
        data: {
          availablePrinciple: 0,
          couponTip: false
        },
        dialog: {
          changeBankAccount: false
        },
        actives: {
          selectAmount: false
        }
      }
    }
  },
  mounted () {
    const stepArcText = new ArcText(document.getElementById('arc-text-step'))
    stepArcText.arc(100).direction(-1)
    this.listProgram()
    this.checkCouponStatus()
  },
  methods: {
    checkCouponStatus () {
      OnlineApi.checkCoupon((res) => {
        this.layouts.data.couponTip = res === this.assist.dataType.COUPON_EXIST.EXIST.value
      })
    },
    listProgram () {
      OnlineApi.getLoanInfo(
        this.$store.getters.getLoan.loanNo,
        (result) => {
          if (tool.isEmpty(result)) return

          if (tool.isNotEmpty(result.qualifiedPrincipal)) {
            this.roundSliderDefinition.max = result.qualifiedPrincipal
          } else {
            this.roundSliderDefinition.max = 200
            this.layouts.actives.selectAmount = true
          }

          document.getElementById('arc-text-pre-qualified').innerText = `PRE-QUALIFIED FOR ${this.assist.currency.format(result.qualifiedPrincipal)}`
          this.preQualifiedArcText = new ArcText(document.getElementById('arc-text-pre-qualified'))
          this.preQualifiedArcText.arc(100)
          this.roundSliderDefinition.sliderValue = result.qualifiedPrincipal || 200
          this.$store.commit('setProgram', result)
        },
        () => {
          this.roundSliderDefinition.max = 200
          this.layouts.actives.selectAmount = true
        }
      )
    },
    currencyFormat (e) {
      return (
        '<div class="round-slider-label">' +
        '<span class="amount">' +
        this.assist.currency.format(e.value).replace('$', '') +
        '</span>' +
        '</div>'
      )
    },
    onClickChangeBank () {
      this.$router.push('/guidance')
    },
    onClickContinue () {
      const approvedPrincipal = this.roundSliderDefinition.sliderValue
      if (approvedPrincipal % 25 !== 0) {
        return this.$store.commit('setPopupInformation', {
          message: 'The amount needs to be a multiple of 25.'
        })
      }

      this.$store.commit('setApprovedAmount', approvedPrincipal)
      this.$router.push('/disbursement/choose-payment-mode')
    }
  }
}
</script>

<style lang="sass" scoped>
.color-primary
  color: var(--theme_primary)

.color-secondary
  color: var(--theme_secondary)

.arc-text-container
  position: absolute
  display: flex
  flex-flow: column
  height: 272px
  width: 272px
  padding: 40px
  font-size: 14px
  color: #9A9A9A

.coupon-tip
  display: flex
  background: var(--theme_primary_opacity_low)
  border-radius: 5px

#arc-text-step
  color: rgba(255, 255, 255, 0.6)

#arc-text-pre-qualified
  color: rgba(255, 255, 255, 0.6)

.link
  text-align: center
  text-decoration: underline
  color: var(--theme_primary)
</style>

<style lang="sass">
//style of loan amount of round-slider
.round-slider-label
  font-family: 'PT Sans', sans-serif !important
  font-size: 40px
  font-weight: 700
  line-height: 1.0
  display: flex
  align-items: flex-start
  padding-top: 15px
  color: #FFFFFF

  & .amount:before
    font-size: 20px
    content: '$'
</style>
